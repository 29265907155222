import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TourProvider, useTour } from '@reactour/tour';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Button, Layout } from 'antd';
import { useMount, useLocalStorage } from 'react-use';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import moment from 'moment';
import envConfig from '../envConfig';
import Footer from './shared-components/Footer';
import Header from './shared-components/header/Header';
import Sidebar from './shared-components/sidebar/Sidebar';
import Payouts from './pages/payouts/Payouts';
import {
  profileAtom,
  impersonationAtom,
  stateAtom,
  tokenAtom,
  businessAtom,
  businessProfileAtom,
  selectedTerminalDataAtom
} from '../atoms/Atoms';
import ProductSorting from './pages/store/ProductSorting';
import ProductSearch from './pages/store/ProductsSearch';
import Downloads from './pages/downloads/Downloads';
import Team from './pages/store/Staff';
import CustomerSearch from './pages/store/CustomerSearch';
import StoreOptions from './pages/settings/StoreOptions';
import StoreDocuments from './pages/settings/StoreDocuments';
import Documents from './pages/documents/Documents';
import OrderSearch from './pages/store/Orders';
import Messaging from './pages/store/Messaging';
import Business from './pages/options/BusinessLocation';
import Hours from './pages/options/OperationHours';
import StoreCoupons from './pages/store/StoreCoupons';
import PaymentConfigurations from './pages/payments-config/Configurations';
import Bins from './pages/store/Bins';
import EditProduct from './shared-components/drawers/EditProduct';
import TicketBoards from './pages/tickets/TicketBoards';
import Tickets from './pages/tickets/Tickets';
import ItemMaster from './pages/itemmaster/ItemMaster';
import InfoCards from './pages/infocards/InfoCards';
import PromoBanners from './pages/promobanners/PromoBanners';
import PromoEmails from './pages/promoemails/PromoEmails';
import PromoSms from './pages/promosms/PromoSms';
import DashboardLegacy from './pages/dashboardlegacy/Dashboard';
import Dashboard from './pages/dashboard/Dashboard';
import DepartmentsFinance from './pages/reports/DepartmentsFinance';
import StoreDepartmentsFinance from './pages/reports/StoreDepartmentsFinance';
import StoreCategoriesFinance from './pages/reports/StoreCategoriesFinance';
import SalesFinance from './pages/reports/SalesFinance';
import PosSalesFinance from './pages/reports/PosSalesFinance';
import InStoreSalesFinance from './pages/reports/InStoreSalesFinance';
import EmployeeInStoreSalesFinance from './pages/reports/shared-components/EmployeeInStoreSalesFinance';
import PosSalesSummary from './pages/reports/PosSalesSummary';
import TerminalFinance from './pages/reports/TerminalFinance';
import Loyalty from './pages/loyalty/Loyalty';
import BusinessAccounts from './pages/businessaccounts/BusinessAccounts';
import ItemRequirements from './pages/itemmaster/ItemRequirements';
import ItemSides from './pages/itemmaster/ItemSides';
import ItemAddons from './pages/itemmaster/ItemAddons';
import ProductCampaigns from './pages/productcampaigns/ProductCampaigns';
import ProductCampaign from './pages/productcampaigns/ProductCampaign';
import Faq from './pages/help/Faq';
import KnowledgeBases from './pages/help/KnowledgeBases';
import UserImpersonation from './pages/impersonation/UserImpersonation';
import Delivery from './pages/delivery/Delivery';
import CreateNewBusiness from './pages/store/CreateNewBusiness';
import CustomDepartments from './pages/settings/CustomDepartments';
import ItemPrices from './pages/itemprices/ItemPrices';
import CouponCampaigns from './pages/couponcampaigns/CouponCampaigns';
import Coupon from './pages/couponcampaigns/Coupon';
import '../assets/override.css';
import Home from './pages/home/Home';
import UserAnalytics from './pages/analytics/UserAnalytics';
import PushNotifications from './pages/pushnotifications/PushNotifications';
import Deliveries from './pages/store/Deliveries';
import StoreDashboardLegacy from './pages/store-dashboardlegacy/StoreDashboard';
import StoreDashboard from './pages/store-dashboard/StoreDashboard';
import RedirectHandler from './pages/redirect-handler/RedirectHandler';
import OrderDetails from './pages/orderdetails/OrderDetails';
import CsOrderDetails from './pages/orderdetails/CsOrderDetails';
import DeliveryDetails from './pages/deliverydetails/DeliveryDetails';
import CsOrderSearch from './pages/cs-orders/Orders';
import Sponsorships from './pages/sponsorships/Sponsorships';
import Sponsorship from './pages/sponsorships/Sponsorship';
import Merchandiser from './pages/merchandiser/Merchandiser';
import DeliveryProviders from './pages/deliveryproviders/DeliveryProviders';
import DeliveryProviderReports from './pages/deliveryproviders/DeliveryProviderReports';
import CardRestrictions from './pages/cardrestrictions/CardRestrictions';
import ExternalContacts from './pages/externalcontacts/ExternalContacts';
import PromoCodes from './pages/promocodes/PromoCodes';
import Profile from './pages/profile/Profile';
import HelperInfo from './shared-components/drawers/HelperInfo';
import SearchOptimization from './pages/searchoptimization/SearchOptimization';
import TodayLoss from './pages/sales/TodayLoss';
import CurrentLoss from './pages/sales/CurrentLoss';
import YesterdayLoss from './pages/sales/YesterdayLoss';
import Images from './pages/images/Images';
import PastLoss from './pages/sales/PastLoss';
import ServerConfigurations from './pages/configurations/ServerConfigurations';
import AppConfigurations from './pages/configurations/AppConfigurations';
import ManagerConfiguration from './pages/configurations/ManagerConfiguration';
import Departments from './pages/departments/Departments';
import Sections from './pages/departments/Sections';
import ReferralEmail from './pages/referrals/ReferralEmailPage';
import GlobalPayouts from './pages/payouts-global/GlobalPayouts';
import GlobalLogs from './pages/logs/GlobalLogs';
import BusinessLogs from './pages/logs/BusinessLogs';
import CheckHealth from './pages/health/CheckHealth';
import Register from './pages/register/Register';
import TopProducts from './pages/reports/TopProducts';
import Promotions from './pages/promotions/Promotions';
import BookingEvents from './pages/booking/events/Events';
import BookingHosts from './pages/booking/hosts/Hosts';
import BookingLocations from './pages/booking/locations/Locations';
import BookingPurchaseHistory from './pages/booking/reports/BookingPurchaseHistory';
import BookingsHistory from './pages/booking/reports/BookingsHistory';
import BookingsHistoryReport from './pages/booking/reports/BookingsHistoryReport';
import BookingsReport from './pages/booking/reports/BookingsReport';
import BookingEventsHeatMap from './pages/booking/reports/BookingEventsHeatMap';
import BookingSalesHeatMap from './pages/booking/reports/BookingSalesHeatMap';
import ServiceTypes from './pages/booking/servicetypes/ServiceTypes';
import Pos from './pages/pos/Pos';
import POSCashDrawerCheckIn from './pages/pos/POSCashDrawerCheckIn';
import api from '../api/api';
import StoreItemPrices from './pages/itemprices/StoreItemPrices';
import Inventory from './pages/inventory/Inventory';
import PendingPayment from './pages/booking/reports/PendingPayment';
import Subscriptions from './pages/booking/subscriptions/Subscriptions';
import TosAcceptenceModal from './shared-components/modals/TosAcceptenceModal';
import DocumentAcceptance from './pages/document-acceptance/DocumentAcceptance';
import Performance from './pages/store/shared-components/Performance';
import UserSubscriptions from './pages/booking/user-subscriptions/UserSubscriptions';
import SubscriptionsSalesReport from './pages/booking/subscriptions/SubscriptionsSalesReport';
import SubscriptionHistory from './pages/booking/reports/SubscriptionsHistory';
import PosDiscounts from './pages/posdiscounts/PosDiscounts';
import TestConsumerSearch from './pages/itemmaster/TestConsumerSearch';
import TerminalDataModal from './shared-components/modals/TerminalDataModal';
import EmployeeInStore from './pages/reports/EmployeeInStore';
import TimeSlotHostsReport from './pages/booking/reports/TimeSlotHostsReport';
import PosDiscountsReport from './pages/reports/PosDiscountsReport';
import JsonPreviewModal from './shared-components/modals/JsonPreviewModal';
import AccountRequests from './pages/accountrequests/AccountRequests';

function App() {
  const { i18n } = useTranslation();

  const [language] = useLocalStorage(`${envConfig.REACT_APP_PREFIX}-admin-lang`);
  const [token, setToken] = useRecoilState(tokenAtom);
  const [state, setState] = useRecoilState(stateAtom);
  const [store, setStore] = useRecoilState(businessAtom);
  const setBusinessProfile = useSetRecoilState(businessProfileAtom);
  const [impersonation, setImpersonation] = useRecoilState(impersonationAtom);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useRecoilState(profileAtom);
  const selectedTerminalData = useRecoilValue(selectedTerminalDataAtom);
  const { setIsOpen } = useTour();

  const steps = [
    {
      selector: '.first-step',
      content: 'This is the language switcher',
    },
    {
      selector: '.logout',
      content: 'This is the Logout button',
    },
    {
      selector: '.profile',
      content: 'This is the profile button',
    },
    {
      selector: '.reload',
      content: 'This is the reload button',
    },
  ];

  const getImper = () => {
    const savedImper = localStorage.getItem(
      `${envConfig.REACT_APP_PREFIX}-admin-imper`
    );
    if (savedImper) setImpersonation(JSON.parse(savedImper));
  };

  const getProfile = () => {
    const savedProfile = localStorage.getItem(
      `${envConfig.REACT_APP_PREFIX}-admin-profile`
    );
    if (savedProfile) setProfile(JSON.parse(savedProfile));
  };

  const getState = () => {
    const savedState = localStorage.getItem(
      `${envConfig.REACT_APP_PREFIX}-admin-currentstate`
    );
    if (savedState) setState(JSON.parse(savedState));
  };

  const getStore = () => {
    const savedStore = localStorage.getItem(
      `${envConfig.REACT_APP_PREFIX}-admin-currentstore`
    );
    if (savedStore) setStore(JSON.parse(savedStore));
  };

  const getToken = () => {
    setLoading(true);
    let expired = true;

    const accessToken = localStorage.getItem(
      `${envConfig.REACT_APP_PREFIX}-admin-token`
    );
    const expirationDate = localStorage.getItem(
      `${envConfig.REACT_APP_PREFIX}-admin-expire`
    );

    if (expirationDate) {
      expired = moment().isAfter(expirationDate);
    }

    if (accessToken && !expired) {
      setToken(accessToken);
    } else {
      localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-token`);
      localStorage.removeItem(`${envConfig.REACT_APP_PREFIX}-admin-expire`);
      setToken(null);
    }
    setLoading(false);
  };

  function updateLanguage() {
    if (profile) {
      api.post('users/me', {
        preferredLanguage: i18n.language === 'en' ? 0 : 1
      });
    }
  }

  useEffect(() => {
    updateLanguage();
  }, [language, i18n.language, profile]);

  useMount(() => {
    if (language) i18n.changeLanguage(language);
    getToken();
    getState();
    getProfile();
    getStore();
    getImper();
  });

  useEffect(() => {
    const interval = setInterval(() => getToken(), 3600000);
    return () => clearInterval(interval);
  }, []);

  function setCurrentBusinessProfile(p, s) {
    const selectedProfile = _.find(
      p.businessProfiles,
      (v) => v.businessId === s.id
    );

    if (selectedProfile) {
      setBusinessProfile(selectedProfile);
    }
    return selectedProfile;
  }

  useEffect(() => {
    if (store && profile) {
      setCurrentBusinessProfile(profile, store);
      localStorage.setItem(
        `${envConfig.REACT_APP_PREFIX}-admin-currentstore`,
        JSON.stringify(store)
      );
    }
  }, [store]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if ((!token || !state || !profile) && !loading) {
    return (
      <Router>
        <Layout style={{ height: '100vh' }}>
          <Layout.Content className="bg-white overflow-auto scrollbar-thin">
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
          </Layout.Content>
        </Layout>
      </Router>
    );
  }

  return (
    <Router>
      <Layout style={{ height: '100vh' }}>
        <TourProvider
          steps={steps}
          onRequestClose={setIsOpen(false)}
        >
          <Sidebar />
          <JsonPreviewModal />
          <EditProduct />
          <Layout>
            <Header />
            <Layout.Content className="bg-white overflow-auto mx-4 pl-4 pr-2 scrollbar-thin">
              <Switch>
                <Route exact path="/">
                  <RedirectHandler />
                </Route>
                <Route exact path="/customers/requests">
                  <AccountRequests />
                </Route>
                <Route exact path="/consumersearch">
                  <TestConsumerSearch />
                </Route>
                <Route exact path="/booking/servicetypes">
                  <ServiceTypes />
                </Route>
                <Route exact path="/booking/subscriptions">
                  <Subscriptions />
                </Route>
                <Route exact path="/register">
                  <Register />
                </Route>
                <Route exact path="/configuration/app">
                  <AppConfigurations />
                </Route>
                <Route exact path="/configuration/server">
                  <ServerConfigurations />
                </Route>
                <Route exact path="/configuration/manager">
                  <ManagerConfiguration />
                </Route>
                <Route exact path="/pos">
                  <Pos />
                </Route>
                <Route exact path="/pos/cashdrawer/report">
                  <POSCashDrawerCheckIn />
                </Route>
                <Route path="/currentloss">
                  <CurrentLoss />
                </Route>
                <Route path="/pastloss">
                  <PastLoss />
                </Route>
                <Route path="/images">
                  <Images />
                </Route>
                <Route path="/promotions">
                  <Promotions />
                </Route>
                <Route path="/yesterdayloss">
                  <YesterdayLoss />
                </Route>
                <Route path="/todayloss">
                  <TodayLoss />
                </Route>
                <Route exact path="/dashboard">
                  <Dashboard />
                  {/* <DashboardLegacy /> */}
                </Route>
                <Route exact path="/dashboardlegacy">
                  <DashboardLegacy />
                </Route>
                <Route exact path="/contacts">
                  <ExternalContacts />
                </Route>
                <Route exact path="/promocodes">
                  <PromoCodes />
                </Route>
                <Route exact path="/cardrestrictions">
                  <CardRestrictions />
                </Route>
                <Route exact path="/deliveryproviders">
                  <DeliveryProviders />
                </Route>
                <Route exact path="/deliveryproviderreports">
                  <DeliveryProviderReports />
                </Route>
                <Route exact path="/storedashboard">
                  {/* <StoreDashboard /> */}
                  <StoreDashboardLegacy />
                </Route>
                <Route exact path="/storedashboardlegacy">
                  <StoreDashboardLegacy />
                </Route>
                <Route exact path="/finance/sales">
                  <SalesFinance />
                </Route>
                <Route exact path="/finance/pos/discounts">
                  <PosDiscountsReport />
                </Route>
                <Route exact path="/finance/instore/sales">
                  <InStoreSalesFinance />
                </Route>
                <Route exact path="/finance/instore/employees/sales">
                  <EmployeeInStore />
                </Route>
                <Route exact path="/finance/pos/sales">
                  <PosSalesFinance />
                </Route>
                <Route exact path="/finance/pos/summary">
                  <PosSalesSummary />
                </Route>
                <Route exact path="/finance/departments">
                  <DepartmentsFinance />
                </Route>
                <Route exact path="/finance/store/departments">
                  <StoreDepartmentsFinance />
                </Route>
                <Route exact path="/finance/store/categories">
                  <StoreCategoriesFinance />
                </Route>
                <Route exact path="/finance/terminal">
                  <TerminalFinance />
                </Route>
                <Route exact path="/loyalty">
                  <Loyalty />
                </Route>
                <Route path="/pushnotifications">
                  <PushNotifications />
                </Route>
                <Route path="/sorting">
                  <ProductSorting />
                </Route>
                <Route path="/messaging-store">
                  <Messaging />
                </Route>
                <Route path="/payouts">
                  <Payouts />
                </Route>
                <Route path="/globalpayouts">
                  <GlobalPayouts />
                </Route>
                <Route path="/products-search">
                  <ProductSearch />
                </Route>
                <Route path="/downloads">
                  <Downloads />
                </Route>
                <Route path="/team">
                  <Team />
                </Route>
                <Route path="/customers">
                  <CustomerSearch />
                </Route>
                <Route path="/store-options">
                  <StoreOptions />
                </Route>
                <Route path="/orders">
                  <CsOrderSearch />
                </Route>
                <Route path="/order-search">
                  <OrderSearch />
                </Route>
                <Route path="/business-info">
                  <Business />
                </Route>
                <Route path="/hours">
                  <Hours />
                </Route>
                <Route path="/storecoupons">
                  <StoreCoupons />
                </Route>
                <Route path="/payment-config">
                  <PaymentConfigurations />
                </Route>
                <Route path="/bins">
                  <Bins />
                </Route>
                <Route path="/ticketboards">
                  <TicketBoards />
                </Route>
                <Route path="/ticketboard/:boardId">
                  <Tickets />
                </Route>
                <Route path="/ticketboard/:boardId/:ticketId">
                  <Tickets />
                </Route>
                <Route path="/itemmaster">
                  <ItemMaster />
                </Route>
                <Route path="/requirements">
                  <ItemRequirements />
                </Route>
                <Route path="/sides">
                  <ItemSides />
                </Route>
                <Route path="/addons">
                  <ItemAddons />
                </Route>
                <Route path="/infocards">
                  <InfoCards />
                </Route>
                <Route path="/csorderDetails">
                  <CsOrderDetails />
                </Route>
                <Route path="/orderDetails">
                  <OrderDetails />
                </Route>
                <Route path="/promobanners">
                  <PromoBanners />
                </Route>
                <Route path="/promoemails">
                  <PromoEmails />
                </Route>
                <Route path="/promosms">
                  <PromoSms />
                </Route>
                <Route path="/productcampaigns">
                  <ProductCampaigns />
                </Route>
                <Route path="/store-documents">
                  <StoreDocuments />
                </Route>
                <Route path="/documents">
                  <Documents />
                </Route>
                <Route path="/productcampaign/:campaignId">
                  <ProductCampaign />
                </Route>
                <Route path="/accounts">
                  <BusinessAccounts />
                </Route>
                <Route path="/faq">
                  <Faq />
                </Route>
                <Route path="/impersonation">
                  <UserImpersonation />
                </Route>
                <Route path="/help/KnowledgeBases">
                  <KnowledgeBases />
                </Route>
                <Route path="/delivery">
                  <Delivery />
                </Route>
                <Route path="/departments">
                  <Departments />
                </Route>
                <Route path="/sections">
                  <Sections />
                </Route>
                <Route path="/customdepartments">
                  <CustomDepartments />
                </Route>
                <Route path="/itemprices">
                  <ItemPrices />
                </Route>
                <Route path="/storeitemprices">
                  <StoreItemPrices />
                </Route>
                <Route path="/inventory">
                  <Inventory />
                </Route>
                <Route path="/coupons">
                  <CouponCampaigns />
                </Route>
                <Route path="/coupon/:couponId">
                  <Coupon />
                </Route>
                <Route path="/userAnalytics">
                  <UserAnalytics />
                </Route>
                <Route path="/deliveries">
                  <Deliveries />
                </Route>
                <Route path="/delivery-details">
                  <DeliveryDetails />
                </Route>
                <Route path="/sponsorships">
                  <Sponsorships />
                </Route>
                <Route path="/sponsorship/:sponsorshipId">
                  <Sponsorship />
                </Route>
                <Route path="/merchandiser">
                  <Merchandiser />
                </Route>
                <Route path="/profile">
                  <Profile />
                </Route>
                <Route path="/searchoptimization">
                  <SearchOptimization />
                </Route>
                <Route path="/referrals">
                  <ReferralEmail />
                </Route>
                <Route path="/logs">
                  <GlobalLogs />
                </Route>
                <Route path="/businesslogs">
                  <BusinessLogs />
                </Route>
                <Route path="/topProducts">
                  <TopProducts />
                </Route>
                <Route path="/posdiscounts">
                  <PosDiscounts />
                </Route>
                <Route path="/check-health">
                  <CheckHealth />
                </Route>
                <Route path="/booking/reports/timeslot/hosts">
                  <TimeSlotHostsReport />
                </Route>
                <Route path="/booking/reports/purchaseshistory">
                  <BookingPurchaseHistory />
                </Route>
                <Route path="/booking/reports/bookingshistory">
                  <BookingsHistoryReport />
                </Route>
                <Route path="/booking/reports/subscriptionhistory">
                  <SubscriptionHistory />
                </Route>
                <Route path="/booking/reports/pendingpayment">
                  <PendingPayment />
                </Route>
                <Route path="/subscriptions/reports/sales">
                  <SubscriptionsSalesReport />
                </Route>
                <Route path="/booking/reports/bookings">
                  <BookingsReport />
                </Route>
                <Route path="/booking/events">
                  <BookingEvents />
                </Route>
                <Route path="/booking/hosts">
                  <BookingHosts />
                </Route>
                <Route path="/booking/locations">
                  <BookingLocations />
                </Route>
                <Route path="/booking/heatmap/sales">
                  <BookingSalesHeatMap />
                </Route>
                <Route path="/booking/heatmap/bookings">
                  <BookingEventsHeatMap />
                </Route>
                <Route path="/document-acceptance">
                  <DocumentAcceptance />
                </Route>
                <Route path="/performance">
                  <Performance />
                </Route>
                <Route path="/subscription/reports/users">
                  <UserSubscriptions />
                </Route>
              </Switch>
            </Layout.Content>
            <Footer />
            {impersonation && (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  localStorage.setItem(
                    `${envConfig.REACT_APP_PREFIX}-admin-token`,
                    impersonation.accessToken
                  );
                  localStorage.removeItem(
                    `${envConfig.REACT_APP_PREFIX}-admin-imper`
                  );
                  localStorage.setItem(
                    `${envConfig.REACT_APP_PREFIX}-admin-currentstate`,
                    JSON.stringify(impersonation.state)
                  );
                  localStorage.setItem(
                    `${envConfig.REACT_APP_PREFIX}-admin-profile`,
                    JSON.stringify(impersonation.profile)
                  );
                  setProfile(impersonation.profile);
                  setState(impersonation.state);
                  setToken(impersonation.accessToken);
                  setImpersonation(null);
                }}
              >
                Stop User Impersonation
              </Button>
            )}
          </Layout>
          <CreateNewBusiness />
          <HelperInfo />
          <TosAcceptenceModal />
          {selectedTerminalData ? <TerminalDataModal /> : null }
        </TourProvider>
      </Layout>
    </Router>
  );
}

export default App;
