import React, { useState, useEffect } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  notification,
  Avatar,
  Tooltip,
  Drawer,
  Table,
  Input,
  InputNumber,
  Switch,
  Popconfirm,
  Upload,
  Image
} from 'antd';
import { Comment } from '@ant-design/compatible';
import { PageHeader } from '@ant-design/pro-layout';
import {
  PlusOutlined,
  InboxOutlined
} from '@ant-design/icons';
import {
  timezoneAtom,
  showAddItemAtom,
  showManageFiltersAtom,
  showAddFilterAtom,
  setSelectedMasterItemAtom,
  setSelectedBusinessItemAtom,
  showItemMediaAtom,
  showItemFiltersAtom,
  showCopyToDrawerAtom,
  showItemGtinsAtom,
  showItemUpcsAtom,
  showItemRecommendationsAtom,
  showAddItemMediaAtom,
  showEditItemPriceAtom,
  showAddItemRecommendationAtom,
  manageRequirementsAtom,
  manageSidesAtom,
  manageAddonsAtom,
  showItemSubstitutesAtom,
  showAddItemSubstituteAtom,
  setSearchMetadataAtom,
  profileAtom,
  selectedItemForLogsAtom,
  selectedBusinessItemForLogsAtom
} from '../../../atoms/Atoms';
import Search from './shared-components/Search';
import CreateItemModal from './shared-components/CreateItemModal';
import ManageFiltersDrawer from './shared-components/ManageFiltersDrawer';
import AddFilterModal from './shared-components/AddFilterModal';
import EditItemDrawer from './shared-components/EditItemDrawer';
import EditBusinessItemDrawer from './shared-components/EditBusinessItemDrawer';
import ManageItemFiltersDrawer from './shared-components/ManageItemFiltersDrawer';
import ManageItemRecommendationsDrawer from './shared-components/ManageItemRecommendationsDrawer';
import ManageItemMediaDrawer from './shared-components/ManageItemMediaDrawer';
import AddRecommendationModal from './shared-components/AddRecommendationModal';
import AddItemMediaModal from './shared-components/AddItemMediaModal';
import EditItemPriceDrawer from './shared-components/EditItemPriceDrawer';
import ItemGtinsDrawer from './shared-components/ItemGtinsDrawer';
import BusinessItemUpcsDrawer from './shared-components/BusinessItemUpcsDrawer';
import ManageRequirementsDrawer from './shared-components/ManageRequirementsDrawer';
import ManageSidesDrawer from './shared-components/ManageSidesDrawer';
import ManageAddonsDrawer from './shared-components/ManageAddonsDrawer';
import ManageItemSubstitutesDrawer from './shared-components/ManageItemSubstitutesDrawer';
import AddSubstituteModal from './shared-components/AddSubstituteModal';
import CopyToDrawer from './shared-components/CopyToDrawer';
import { configSelector } from '../../../atoms/Selectors';
import Grocefy from '../../../assets/images/grocefyLogoAlone.png';
import api from '../../../api/api';
import { renderDate } from '../../utils/functions';
import MetadataDrawer from './shared-components/MetadataDrawer';
import ItemLogsModal from './shared-components/ItemLogsModal';
import BusinessItemLogsModal from './shared-components/BusinessItemLogsModal';

function ItemMaster() {
  const { Dragger } = Upload;
  const timezone = useRecoilValue(timezoneAtom);
  const setShowAddItemAtom = useSetRecoilState(showAddItemAtom);
  const setShowManageFilters = useSetRecoilState(showManageFiltersAtom);
  const setShowCopyToDrawer = useSetRecoilState(showCopyToDrawerAtom);
  const showEditItemPrice = useRecoilValue(showEditItemPriceAtom);
  const showItemGtins = useRecoilValue(showItemGtinsAtom);
  const showItemUpcs = useRecoilValue(showItemUpcsAtom);
  const showAddFilter = useRecoilValue(showAddFilterAtom);
  const selectedMasterItem = useRecoilValue(setSelectedMasterItemAtom);
  const searchMetadata = useRecoilValue(setSearchMetadataAtom);
  const selectedBusinessItem = useRecoilValue(setSelectedBusinessItemAtom);
  const showAddItemMedia = useRecoilValue(showAddItemMediaAtom);
  const showAddItemRecommendation = useRecoilValue(showAddItemRecommendationAtom);
  const showAddItemSubstitute = useRecoilValue(showAddItemSubstituteAtom);
  const showItemMedia = useRecoilValue(showItemMediaAtom);
  const showItemFilters = useRecoilValue(showItemFiltersAtom);
  const showItemRecommendations = useRecoilValue(showItemRecommendationsAtom);
  const showItemSubstitutes = useRecoilValue(showItemSubstitutesAtom);
  const manageRequirements = useRecoilValue(manageRequirementsAtom);
  const manageSides = useRecoilValue(manageSidesAtom);
  const manageAddons = useRecoilValue(manageAddonsAtom);
  const selectedItemForLogs = useRecoilValue(selectedItemForLogsAtom);
  const selectedBusinessItemForLogs = useRecoilValue(selectedBusinessItemForLogsAtom);
  const config = useRecoilValueLoadable(configSelector);
  const { t } = useTranslation();
  const [view, setView] = useState('list');
  const [imageData, setImageData] = useState(null);
  const [thumbnailImageData, setThumbnailImageData] = useState(null);
  const width = window.innerWidth;

  const imageDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setImageData(file);
      return false;
    }
  };

  const imageThumbnailDraggerProps = {
    name: 'file',
    accept: 'image/*',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setThumbnailImageData(file);
      return false;
    }
  };
  const userProfile = useRecoilValueLoadable(profileAtom);
  const isAdmin =
    userProfile.contents && userProfile?.contents?.roles?.includes('SuperAdmin');
  const isCustomerService =
    isAdmin || (userProfile?.contents && (userProfile?.contents?.roles?.includes('CustomerServiceAdmin') || userProfile?.contents?.roles?.includes('CustomerService')));
  const isStoreManager =
    isAdmin || isCustomerService || (userProfile?.contents && (userProfile?.contents?.roles?.includes('StoreManager') || userProfile?.contents?.roles?.includes('StoreAdmin')));
  const history = useHistory();

  if (!isAdmin && !isCustomerService && !isStoreManager) {
    history.push('/');
    return null;
  }

  const showMessage = function (message) {
    notification.open({
      message: '',
      description: (
        <Comment
          author={<span>Grocefy</span>}
          avatar={<Avatar src={Grocefy} alt="grocefy" />}
          content={
            <p className="text-sm">
              {message}
            </p>
          }
          datetime={
            <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
              <span>{moment().fromNow()}</span>
            </Tooltip>
          }
        />
      ),
    });
  };

  const [openNewRequestsDrawer, setOpenNewRequestsDrawer] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [data, setData] = useState(null);
  const [newRequests, setNewRequests] = useState([]);
  const [loadingNewRequests, setLoadingNewRequests] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  async function uploadImage(tempImageData) {
    const file = tempImageData;
    const boundary = Date.now();
    const formdata = new FormData();
    formdata.append('file', file);
    const response = await api
      .post('utility/itemMedia/uploadfile', formdata);
    if (response.data.success) {
      return response.data.data;
    }
    console.error(response.data.error);
    return null;
  }
  function loadNewRequests() {
    setLoadingNewRequests(true);
    api
      .get('businesses/catalog/newproducts/itemRequests')
      .then((response) => {
        if (response.data.success) {
          setLoadingNewRequests(false);
          setNewRequests(response.data.data.results);
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingNewRequests(false);
        showMessage(error);
      });
  }
  function updateRequest() {
    setLoadingRequest(true);
    api
      .post(`businesses/catalog/newproducts/itemRequests/update/${selectedRequest?.id}`, data)
      .then((response) => {
        if (response.data.success) {
          setLoadingRequest(false);
          showMessage(t('updated_successfully'));
          loadNewRequests();
        } else {
          showMessage(response.data.error);
        }
      })
      .catch((error) => {
        setLoadingRequest(false);
        showMessage(error);
      });
  }

  function denyRequest() {
    const updatePromise = new Promise(function (resolve) {
      updateRequest();
      resolve();
    });
    updatePromise.then(() => {
      setLoadingRequest(true);
      let assignToAll = false;
      if (data.assignToAllStores) {
        assignToAll = true;
      }
      api
        .delete(`businesses/catalog/newproducts/itemRequests/${selectedRequest?.id}/${assignToAll}`)
        .then((response) => {
          if (response.data.success) {
            setLoadingRequest(false);
            showMessage(t('rejected_successfully'));
            setData(null);
            setSelectedRequest(null);
            loadNewRequests();
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoadingRequest(false);
          showMessage(error);
        });
    });
  }

  function approveRequest() {
    const updatePromise = new Promise(function (resolve) {
      updateRequest();
      resolve();
    });
    updatePromise.then(() => {
      setLoadingRequest(true);
      let assignToAll = false;
      if (data.assignToAllStores) {
        assignToAll = true;
      }
      api
        .post(`businesses/catalog/newproducts/itemRequests/accept/${selectedRequest?.id}/${assignToAll}`, null)
        .then((response) => {
          if (response.data.success) {
            setLoadingRequest(false);
            showMessage(t('approved_successfully'));
            setData(null);
            setSelectedRequest(null);
            loadNewRequests();
          } else {
            showMessage(response.data.error);
          }
        })
        .catch((error) => {
          setLoadingRequest(false);
          showMessage(error);
        });
    });
  }
  useEffect(() => {
    if (!openNewRequestsDrawer) {
      loadNewRequests();
    }
  }, [openNewRequestsDrawer]);
  useEffect(() => {
    loadNewRequests();
  }, []);
  useEffect(async () => {
    if (imageData) {
      const url = await uploadImage(imageData);
      if (url) {
        setImageData(null);
        setData({
          ...data,
          itemImage: url
        });
      }
    }
  }, [imageData]);

  useEffect(async () => {
    if (thumbnailImageData) {
      const url = await uploadImage(thumbnailImageData);
      if (url) {
        setThumbnailImageData(null);
        setData({
          ...data,
          itemThumbnail: url
        });
      }
    }
  }, [thumbnailImageData]);

  useEffect(() => {
    setData(selectedRequest);
  }, [selectedRequest]);

  return (
    <>
      <PageHeader
        className="mb-4 px-0"
        title={t('catalog')}
        subTitle={t('item_master_description')}
        extra={
          [
            <Button
              key="createItemButton"
              type="primary"
              onClick={() => { setShowAddItemAtom(true); }}
            >
              <PlusOutlined />
              {t('create')}
            </Button>,
            <Button
              key="manageFiltersButton"
              onClick={() => { setShowManageFilters(true); }}
            >
              {t('manage_filters')}
            </Button>,
            <Button
              key="copyto"
              type="primary"
              hidden={!isAdmin && !config?.contents?.data?.allowCopyTo}
              onClick={() => { setShowCopyToDrawer(true); }}
            >
              {t('copy_catalog')}
            </Button>,
            <Button
              key="npi"
              type="primary"
              loading={loadingNewRequests}
              hidden={newRequests.length === 0}
              onClick={() => { setOpenNewRequestsDrawer(true); }}
            >
              {newRequests.length} {t('new_requests')}
            </Button>
          ]
        }
      />
      <Search />
      <CreateItemModal />
      {selectedMasterItem && (
        <EditItemDrawer />
      )}
      {searchMetadata && (
        <MetadataDrawer />
      )}
      {!showAddFilter && showManageFiltersAtom && (
        <ManageFiltersDrawer />
      )}
      {showAddFilter && (
        <AddFilterModal />
      )}
      {/* {!selectedBusinessItem &&
        !showItemMedia &&
        !showItemFilters &&
        !showItemGtins &&
        !showItemRecommendations &&
        !manageRequirements &&
        !manageSides &&
        !manageAddons &&
        selectedMasterItem && (
        <EditItemDrawer />
      )} */}
      {showItemGtins && (
        <ItemGtinsDrawer />
      )}
      {showItemUpcs && (
        <BusinessItemUpcsDrawer />
      )}
      {showAddItemRecommendation && (
        <AddRecommendationModal />
      )}
      {showAddItemSubstitute && (
        <AddSubstituteModal />
      )}
      {showAddItemMedia && (
        <AddItemMediaModal />
      )}
      {selectedBusinessItem &&
        !showEditItemPrice &&
        !manageRequirements &&
        !manageSides &&
        !manageAddons &&
        !showItemUpcs && (
        <EditBusinessItemDrawer />
      )}
      {showEditItemPrice && (
        <EditItemPriceDrawer />
      )}
      {showItemMedia && (
        <ManageItemMediaDrawer />
      )}
      {showItemFilters && !showAddItemMedia && (
        <ManageItemFiltersDrawer />
      )}
      {showItemRecommendations && !showAddItemRecommendation && (
        <ManageItemRecommendationsDrawer />
      )}
      {showItemSubstitutes && !showAddItemSubstitute && (
        <ManageItemSubstitutesDrawer />
      )}
      {manageRequirements && (
        <ManageRequirementsDrawer />
      )}
      {manageSides && (
        <ManageSidesDrawer />
      )}
      {manageAddons && (
        <ManageAddonsDrawer />
      )}
      {selectedItemForLogs && (
        <ItemLogsModal />
      )}
      {selectedBusinessItemForLogs && (
        <BusinessItemLogsModal />
      )}
      <CopyToDrawer />
      <Drawer
        title={t('new_requests')}
        placement="right"
        closable
        onClose={() => setOpenNewRequestsDrawer(false)}
        open={openNewRequestsDrawer}
        width={width < 1024 ? width : 600}
      >
        <Table
          dataSource={newRequests}
          size="small"
          bordered
          rowKey="id"
          pagination={false}
          columns={[
            {
              title: 'GTIN',
              dataIndex: 'gtin',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('brand'),
              dataIndex: 'brand',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('name'),
              dataIndex: 'name',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('description'),
              dataIndex: 'description',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            },
            {
              title: t('requested_on'),
              dataIndex: 'createdAt',
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{renderDate(text, timezone)}</span>,
            },
            {
              title: '',
              align: 'left',
              className: 'text-xs',
              render: (row) => (
                <Button
                  size="small"
                  type="primary"
                  onClick={() => setSelectedRequest(row)}
                >
                  {t('edit')}
                </Button>
              ),
            },
          ]}
        />
      </Drawer>
      <Drawer
        title={t('edit_new_requests')}
        placement="right"
        closable
        onClose={() => setSelectedRequest(null)}
        open={selectedRequest}
        width={width < 1024 ? width : 600}
      >
        <div className="flex space-x-2">
          <Popconfirm
            okText={t('yes')}
            cancelText={t('no')}
            title={t('are_you_sure_deny_request')}
            onConfirm={() => denyRequest()}
          >
            <Button
              className="ml-auto"
              size="small"
              danger
              loading={loadingRequest}
              type="primary"
            >
              {t('reject')}
            </Button>
          </Popconfirm>
          <Popconfirm
            okText={t('yes')}
            cancelText={t('no')}
            title={t('are_you_sure_approve_request')}
            onConfirm={() => approveRequest()}
          >
            <Button
              size="small"
              loading={loadingRequest}
              type="primary"
            >
              {t('approve')}
            </Button>
          </Popconfirm>
        </div>
        <br />
        <div className="mb-2">
          <strong>{t('assign_to_all')}</strong>
          <Switch
            disabled={loadingRequest}
            className="float-right"
            checked={data?.assignToAllStores}
            onChange={(checked) => {
              setData({
                ...data,
                assignToAllStores: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>GTIN</strong>
          <Input
            disabled={loadingRequest}
            value={data?.gtin}
            onChange={(obj) => {
              setData({
                ...data,
                gtin: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('brand')}</strong>
          <Input
            disabled={loadingRequest}
            value={data?.brand}
            onChange={(obj) => {
              setData({
                ...data,
                brand: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('name')}</strong>
          <Input
            disabled={loadingRequest}
            value={data?.name}
            onChange={(obj) => {
              setData({
                ...data,
                name: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('description')}</strong>
          <Input
            disabled={loadingRequest}
            value={data?.description}
            onChange={(obj) => {
              setData({
                ...data,
                description: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('item_image')}</strong>
          <Input
            disabled={loadingRequest}
            value={data?.itemImage}
            onChange={(obj) => {
              setData({
                ...data,
                itemImage: obj.target.value
              });
            }}
          />
          <div className="flex">
            <Dragger {...imageDraggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p
                className="ant-upload-text"
                style={{ margin: 10 }}
              >
                {t('click_or_drag_content')}
              </p>
            </Dragger>
            {data?.itemImage && (
              <Image
                width={100}
                alt={t('item_image')}
                src={data?.itemImage}
                className="cursor-pointer"
              />
            )}
          </div>
        </div>
        <div className="mb-2">
          <strong>{t('thumbnail')}</strong>
          <Input
            disabled={loadingRequest}
            value={data?.itemThumbnail}
            onChange={(obj) => {
              setData({
                ...data,
                itemThumbnail: obj.target.value
              });
            }}
          />
          <div className="flex">
            <Dragger {...imageThumbnailDraggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p
                className="ant-upload-text"
                style={{ margin: 10 }}
              >
                {t('click_or_drag_content')}
              </p>
            </Dragger>
            {data?.itemThumbnail && (
              <Image
                width={100}
                alt={t('thumbnail')}
                src={data?.itemThumbnail}
                className="cursor-pointer"
              />
            )}
          </div>
        </div>
        {/* <div className="mb-2">
          <strong>{t('suggested_department')}</strong>
          <Input
            disabled={loadingRequest}
            value={data?.suggestedDepartment}
            onChange={(obj) => {
              setData({
                ...data,
                gtin: obj.target.value
              });
            }}
          />
        </div> */}
        <div className="mb-2">
          <strong>{t('rank')}</strong>
          <InputNumber
            disabled={loadingRequest}
            min={0}
            onChange={(obj) => {
              setData({
                ...data,
                internalRank: obj
              });
            }}
            value={data?.internalRank}
          />
        </div>
        <div className="mb-2">
          <strong>{t('search_tags')}</strong>
          <Input
            disabled={loadingRequest}
            value={data?.searchTags}
            onChange={(obj) => {
              setData({
                ...data,
                searchTags: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('internal_id')}</strong>
          <Input
            disabled={loadingRequest}
            value={data?.internalId}
            onChange={(obj) => {
              setData({
                ...data,
                internalId: obj.target.value
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('package_sensitive')}</strong>
          <Switch
            disabled={loadingRequest}
            className="float-right"
            checked={data?.isPackagingSensitive}
            onChange={(checked) => {
              setData({
                ...data,
                isPackagingSensitive: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('temperature_sensitive')}</strong>
          <Switch
            disabled={loadingRequest}
            className="float-right"
            checked={data?.isTemperatureSensitive}
            onChange={(checked) => {
              setData({
                ...data,
                isTemperatureSensitive: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('is_alcohol')}</strong>
          <Switch
            disabled={loadingRequest}
            className="float-right"
            checked={data?.isAlcoholicBeverage}
            onChange={(checked) => {
              setData({
                ...data,
                isAlcoholicBeverage: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('perishable')}</strong>
          <Switch
            disabled={loadingRequest}
            className="float-right"
            checked={data?.isPerishable}
            onChange={(checked) => {
              setData({
                ...data,
                isPerishable: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('is_ebt')}</strong>
          <Switch
            disabled={loadingRequest}
            className="float-right"
            checked={data?.isEbt}
            onChange={(checked) => {
              setData({
                ...data,
                isEbt: checked
              });
            }}
          />
        </div>
        <div className="mb-2">
          <strong>{t('is_id_required')}</strong>
          <Switch
            disabled={loadingRequest}
            className="float-right"
            checked={data?.idRequired}
            onChange={(checked) => {
              setData({
                ...data,
                idRequired: checked
              });
            }}
          />
        </div>
        <Button
          className="float-right my-2"
          size="small"
          loading={loadingRequest}
          type="primary"
          onClick={() => updateRequest()}
        >
          {t('update')}
        </Button>
        <Table
          dataSource={data?.businessItemRequests}
          size="small"
          bordered
          pagination={false}
          columns={[
            {
              title: t('requesting_stores'),
              align: 'left',
              className: 'text-xs',
              render: (text) => <span>{text}</span>,
            }
          ]}
        />
      </Drawer>
    </>
  );
}
export default ItemMaster;
