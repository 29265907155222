import { atom } from 'recoil';
import moment from 'moment';

export const tokenAtom = atom({
  key: 'token',
  default: null,
});

export const stateAtom = atom({
  key: 'currentstate',
  default: null,
});

export const profileAtom = atom({
  key: 'profile',
  default: null,
});

export const impersonationAtom = atom({
  key: 'impersonation',
  default: null,
});

export const businessAtom = atom({
  key: 'business',
  default: null,
});

export const collapseAtom = atom({
  key: 'collapse',
  default: false,
});

export const viewDetailsAtom = atom({
  key: 'viewDetails',
  default: false,
});

export const rowsAtom = atom({
  key: 'rows',
  default: 10,
});

export const pageAtom = atom({
  key: 'page',
  default: 1,
});

export const paypalRowsAtom = atom({
  key: 'paypalRows',
  default: 10,
});

export const paypalPageAtom = atom({
  key: 'paypalPage',
  default: 1,
});

export const stripePayoutsAtom = atom({
  key: 'stripePayouts',
  default: [],
});

export const loadingStripePayoutsAtom = atom({
  key: 'loadingStripePayouts',
  default: false,
});

export const refreshStripePayoutsAtom = atom({
  key: 'refreshStripePayouts',
  default: false,
});

export const refreshPaymentsAtom = atom({
  key: 'refreshPayments',
  default: false,
});

export const paypalPayoutsAtom = atom({
  key: 'paypalPayouts',
  default: [],
});

export const loadingPaypalPayoutsAtom = atom({
  key: 'loadingPaypalPayouts',
  default: false,
});

export const refreshPaypalPayoutsAtom = atom({
  key: 'refreshPaypalPayouts',
  default: false,
});

export const timezoneAtom = atom({
  key: 'timezone',
  default: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

export const serviceAtom = atom({
  key: 'service',
  default: '0',
});
export const createBin = atom({
  key: 'create-bins',
  default: false,
});

export const showHelperInfoAtom = atom({
  key: 'helper-info',
  default: null,
});

export const binAtom = atom({
  key: 'bin-atom',
  default: null,
});

export const reloadBin = atom({
  key: 'reload-bins',
  default: false,
});

export const businessProfileAtom = atom({
  key: 'business-profile',
  default: null,
});

export const productEditAtom = atom({
  key: 'edit-product',
  default: null,
});

export const searchTermAtom = atom({
  key: 'search-term',
  default: null,
});

export const reloadStaff = atom({
  key: 'reload-starff',
  default: false,
});

export const reloadProduct = atom({
  key: 'reload-product',
  default: false,
});
export const selectedOrderAtom = atom({
  key: 'order-selected',
  default: null,
});
export const selectedBusinessAtom = atom({
  key: 'business-selected',
  default: null,
});

export const orderEditAtom = atom({
  key: 'order-edit',
  default: false,
});

export const editUserAtom = atom({
  key: 'edit-user',
  default: false,
});

export const notificationsAtom = atom({
  key: 'notifications',
  default: [],
});

export const reloadCustomers = atom({
  key: 'reload-customer',
  default: false,
});

export const userRolesAtom = atom({
  key: 'user-roles',
  default: null,
});
export const selectedTicketBoardAtom = atom({
  key: 'TicketBoard',
  default: null,
});

export const selectedTicketAtom = atom({
  key: 'Ticket',
  default: null,
});

export const showBoardLogsAtom = atom({
  key: 'showBoardLogs',
  default: false,
});

export const showTicketLogsAtom = atom({
  key: 'showticketLogs',
  default: false,
});

export const showManageBoardTeamAtom = atom({
  key: 'showManageBoardTeam',
  default: false,
});

export const refreshTicketsAtom = atom({
  key: 'refreshTickets',
  default: false,
});

export const editTicketAtom = atom({
  key: 'editTicket',
  default: null,
});

export const editBoardAtom = atom({
  key: 'editBoard',
  default: null,
});

export const showTicketUserAssignAtom = atom({
  key: 'showTicketUserAssign',
  default: false,
});

export const ticketAssignedUserAtom = atom({
  key: 'ticketAssignedUser',
  default: null,
});

export const refreshBoardsAtom = atom({
  key: 'refreshBoards',
  default: false
});

export const showAddTicketLogAtom = atom({
  key: 'showAddTicketLogA',
  default: false
});

export const showAddItemAtom = atom({
  key: 'showAddItem',
  default: false
});

export const showCopyToDrawerAtom = atom({
  key: 'showCopyToDrawer',
  default: false
});

export const setSelectedMasterItemAtom = atom({
  key: 'setSelectedMasterItem',
  default: null
});

export const setSearchMetadataAtom = atom({
  key: 'setSearchMetadata',
  default: null
});

export const setSelectedBusinessItemAtom = atom({
  key: 'setSelectedBusinessItem',
  default: null
});

export const showItemMediaAtom = atom({
  key: 'showItemMedia',
  default: false
});

export const showItemFiltersAtom = atom({
  key: 'showItemFilters',
  default: false
});

export const showItemGtinsAtom = atom({
  key: 'showItemGtins',
  default: false
});

export const showItemUpcsAtom = atom({
  key: 'showItemUpcs',
  default: false
});

export const showItemRecommendationsAtom = atom({
  key: 'showItemRecommendations',
  default: false
});

export const showItemSubstitutesAtom = atom({
  key: 'showItemSubstitutes',
  default: false
});

export const showAddItemSubstituteAtom = atom({
  key: 'showAddItemSubstitute',
  default: false
});

export const showManageFiltersAtom = atom({
  key: 'showManageFilters',
  default: false
});

export const showAddFilterAtom = atom({
  key: 'showAddFilter',
  default: false
});

export const refreshSearchFiltersAtom = atom({
  key: 'refreshSearchFilters',
  default: false
});

export const reloadOrder = atom({
  key: 'reloadOrder',
  default: false
});

export const showAddItemRecommendationAtom = atom({
  key: 'showAddItemRecommendation',
  default: false
});

export const selectedPickingItemAtom = atom({
  key: 'selectedPickingItem',
  default: false
});

export const showRelatedItemPickAtom = atom({
  key: 'showRelatedItemPick',
  default: false
});

export const showAddItemMediaAtom = atom({
  key: 'showAddItemMedia',
  default: null
});
export const orderItemsAtom = atom({
  key: 'orderItems',
  default: false
});

export const orderItemsVisible = atom({
  key: 'orderItemsVisible',
  default: false
});

export const refreshItemMediaAtom = atom({
  key: 'refreshItemMedia',
  default: false
});

export const refreshInfoCardsAtom = atom({
  key: 'refreshInfoCards',
  default: false
});

export const selectedBusinessAccountAtom = atom({
  key: 'selectedBusinessAccount',
  default: null
});

export const refreshBusinessAcountsAtom = atom({
  key: 'refreshBusinessAcounts',
  default: false
});

export const selectedInfoCardAtom = atom({
  key: 'selectedInfoCard',
  default: null
});

export const refreshPromoSmsAtom = atom({
  key: 'refreshPromoSms',
  default: false
});

export const selectedPromoSmsAtom = atom({
  key: 'selectedPromoSms',
  default: null
});

export const refreshPromoEmailsAtom = atom({
  key: 'refreshPromoEmails',
  default: false
});

export const selectedPromoEmailAtom = atom({
  key: 'selectedPromoEmail',
  default: null
});

export const refreshBannersAtom = atom({
  key: 'refreshBanners',
  default: false
});

export const refreshLoyaltiesAtom = atom({
  key: 'refreshLoyalties',
  default: false
});

export const selectedBannerAtom = atom({
  key: 'selectedBanner',
  default: null
});

export const selectedLoyaltyAtom = atom({
  key: 'selectedLoyalty',
  default: null
});

export const addLogAtom = atom({
  key: 'addLog',
  default: false
});

export const showEditItemPriceAtom = atom({
  key: 'showEditItemPrice',
  default: false
});

export const selectedItemPriceAtom = atom({
  key: 'selectedItemPrice',
  default: false
});

export const otherBusinessItemsAtom = atom({
  key: 'otherBusinessItems',
  default: []
});

export const selectedRequirementAtom = atom({
  key: 'selectedRequirement',
  default: null
});

export const refreshRequirementsAtom = atom({
  key: 'refreshRequirements',
  default: false
});

export const selectedSideAtom = atom({
  key: 'selectedSide',
  default: null
});

export const refreshSidesAtom = atom({
  key: 'refreshSides',
  default: false
});

export const selectedAddonAtom = atom({
  key: 'selectedAddon',
  default: null
});

export const refreshAddonsAtom = atom({
  key: 'refreshAddons',
  default: false
});

export const selectedOptionAtom = atom({
  key: 'selectedOption',
  default: null
});

export const changePaymentMethodAtom = atom({
  key: 'changePaymentMethod',
  default: false
});

export const changeOrderStatusAtom = atom({
  key: 'changeOrderStatus',
  default: false
});

export const changeCardAtom = atom({
  key: 'changeCard',
  default: false,
});

export const cardsAtom = atom({
  key: 'cards',
  default: null,
});

export const customCreditAtom = atom({
  key: 'customCredit',
  default: false,
});

export const manageRequirementsAtom = atom({
  key: 'manageRequirements',
  default: false,
});

export const manageSidesAtom = atom({
  key: 'manageSides',
  default: false,
});

export const manageAddonsAtom = atom({
  key: 'manageAddons',
  default: false,
});

export const selectedCampaignAtom = atom({
  key: 'selectedCampaign',
  default: null
});

export const refreshCampaignsAtom = atom({
  key: 'refreshCampaigns',
  default: false
});

export const showCreateCampaignDrawerAtom = atom({
  key: 'showCreateCampaignDrawer',
  default: false
});

export const showEditCampaignDrawerAtom = atom({
  key: 'showEditCampaignDrawer',
  default: false
});

export const selectedPaymentAtom = atom({
  key: 'selectedPayment',
  default: null,
});

export const reloadPaymentOptionsAtom = atom({
  key: 'reloadPayments',
  default: false,
});

export const paymentEditAtom = atom({
  key: 'edit-payment',
  default: false,
});

export const paymentCreateAtom = atom({
  key: 'create-payment',
  default: false,
});

export const refreshKnowledgebasesAtom = atom({
  key: 'refreshKnowledgebases',
  default: false
});

export const selectedKnowledgebase = atom({
  key: 'knowledgebasesAtom',
  default: null
});

export const qnaKnowledgeBaseAtom = atom({
  key: 'qnaKnowledgeBase',
  default: null
});

export const qnaSelectedAnswerAtom = atom({
  key: 'qnaSelectedAnswer',
  default: null
});

export const showCreateDeliveryZoneAtom = atom({
  key: 'showCreateDeliveryZone',
  default: false
});

export const selectedDeliveryZoneAtom = atom({
  key: 'selectedDeliveryZone',
  default: null
});

export const reloadDeliveryZonesAtom = atom({
  key: 'reloadDeliveryZones',
  default: false
});

export const showEditDeliveryZoneHoursAtom = atom({
  key: 'showEditDeliveryZoneHours',
  default: false
});

export const selectedDriverAtom = atom({
  key: 'selectedDriver',
  default: false
});

export const showAddDriverDrawerAtom = atom({
  key: 'showAddDriverDrawer',
  default: false
});

export const reloadDriversAtom = atom({
  key: 'reloadDrivers',
  default: false
});

export const createNewBusinessAtom = atom({
  key: 'createNewBusiness',
  default: false
});
export const openDrawerAtom = atom({
  key: 'openDrawer',
  default: false
});

export const searchTypeAtom = atom({
  key: 'searchType',
  default: false
});

export const selectedCouponAtom = atom({
  key: 'selectedCoupon',
  default: null
});

export const refreshCouponsAtom = atom({
  key: 'refreshCoupons',
  default: false
});

export const refreshUserAnalyticsAtom = atom({
  key: 'refreshUserAnalytics',
  default: false
});

export const selectedPushAtom = atom({
  key: 'pushNotification',
  default: {},
});

export const reloadPushAtom = atom({
  key: 'reload-pn',
  default: false,
});

export const showPushCreateAtom = atom({
  key: 'push-noti-create',
  default: false,
});

export const showPushEditAtom = atom({
  key: 'push-noti-edit',
  default: false,
});

export const financeFilterYearAtom = atom({
  key: 'finance-filter-year-atom',
  default: moment().format('YYYY'),
});

export const financeFilterMonthAtom = atom({
  key: 'finance-filter-month-atom',
  default: moment().format('M'),
});

export const financeFilterDayAtom = atom({
  key: 'finance-filter-day-atom',
  default: moment().format('d'),
});

export const loadingUserAnalyticsAtom = atom({
  key: 'loadUserAnalytics',
  default: false,
});

export const dashboardOptionAtom = atom({
  key: 'dashboardOption',
  default: 0,
});

export const dashboardSelectedStoreAtom = atom({
  key: 'dashboardSelectedStore',
  default: '',
});

export const dashboardPayloadAtom = atom({
  key: 'dashboardPayload',
  default: {}
});

export const ordersAtom = atom({
  key: 'orders',
  default: null,
});

export const deliveryLatAtom = atom({
  key: 'deliveryLat',
  default: null,
});

export const deliveryLonAtom = atom({
  key: 'deliveryLon',
  default: null,
});

export const mapZoomAtom = atom({
  key: 'mapZoom',
  default: 20,
});

export const openDeliveyMapAtom = atom({
  key: 'openDeliveryMap',
  default: false,
});

export const selectedSponsorshipAtom = atom({
  key: 'selectedSponsorship',
  default: null,
});

export const selectedPromotionAtom = atom({
  key: 'selectedPromotion',
  default: null,
});

export const refreshSponsorshipsAtom = atom({
  key: 'refreshSponsorships',
  default: false,
});

export const refreshPromotionsAtom = atom({
  key: 'refreshPromotions',
  default: false,
});

export const showCreateSponsorshipDrawerAtom = atom({
  key: 'showCreateSponsorshipDrawer',
  default: false,
});

export const showEditSponsorshipDrawerAtom = atom({
  key: 'showEditSponsorshipDrawer',
  default: false,
});

export const selectedDeliveryProviderAtom = atom({
  key: 'selectedDeliveryProvider',
  default: null
});

export const refreshDeliveryProviderAtom = atom({
  key: 'refreshDeliveryProvider',
  default: false
});

export const manageDeliveryProviderUsersAtom = atom({
  key: 'manageDeliveryProviderUsers',
  default: false
});

export const manageDeliveryProviderZonesAtom = atom({
  key: 'manageDeliveryProviderZones',
  default: false
});

export const editCardRestrictionAtom = atom({
  key: 'editCardRestriction',
  default: false
});

export const reloadCardRestrictionsAtom = atom({
  key: 'reloadCardRestrictions',
  default: false
});

export const selectedCardRestrictionAtom = atom({
  key: 'selectedCardRestriction',
  default: null
});

export const prepInventoryItemsAtom = atom({
  key: 'prepInventoryItems',
  default: null
});

export const showPrepAtom = atom({
  key: 'showPrep',
  default: false
});

export const prepDateRangeAtom = atom({
  key: 'prepdaterange',
  default: false
});

export const selectedStoreAtom = atom({
  key: 'selectedStore',
  default: null
});

export const openCustomerLocationMapAtom = atom({
  key: 'openCustomerLocationMap',
  default: false,
});

export const customerLocationLatAtom = atom({
  key: 'customerLocationLat',
  default: null,
});

export const customerLocationLonAtom = atom({
  key: 'customerLocationLon',
  default: null,
});

export const customerLocationAtom = atom({
  key: 'customerLocation',
  default: null,
});

export const deliveryZonesAtom = atom({
  key: 'delvieryZones',
  default: [],
});

export const securityCodeAtom = atom({
  key: 'securityCode',
  default: null,
});

export const openSecurityCodeModalAtom = atom({
  key: 'openSecurityCodeModal',
  default: false,
});

export const cancelOrderAtom = atom({
  key: 'cancelOrder',
  default: false,
});

export const selectedPromoCodesAtom = atom({
  key: 'selectedPromoCodes',
  default: null,
});

export const refreshPromoCodesAtom = atom({
  key: 'refreshPromoCodes',
  default: false,
});

export const showEditPromoCodeAtom = atom({
  key: 'showEditPromoCode',
  default: 0,
});

export const openAddItemToCustomDeptAtom = atom({
  key: 'openAddItemToCustomDept',
  default: false
});

export const productToAddAtom = atom({
  key: 'productToAdd',
  default: false
});

export const reloadCustomDeptAtom = atom({
  key: 'reloadCustomDept',
  default: false
});

export const addItemToCustomDeptAtom = atom({
  key: 'addItemToCustomDept',
  default: false
});

export const setSelectedItemAtom = atom({
  key: 'setSelectedItem',
  default: null
});

export const showEditSearchTermAtom = atom({
  key: 'editSearchTerm',
  default: false
});

export const reloadSearchTagsAtom = atom({
  key: 'reloadSearchTags',
  default: false
});

export const pastLossAtom = atom({
  key: 'past-loss-days',
  default: 10,
});

export const refreshImagesAtom = atom({
  key: 'refreshImages',
  default: false
});

export const selectedImageAtom = atom({
  key: 'selectedImage',
  default: null
});

export const imageCategoriesAtom = atom({
  key: 'imageCategories',
  default: []
});

export const imageDepartmentsAtom = atom({
  key: 'imageDepartments',
  default: []
});

export const imageTypesAtom = atom({
  key: 'imageTypes',
  default: []
});

export const selectedDepartmentAtom = atom({
  key: 'selectedDepartment',
  default: null
});

export const selectedSectionAtom = atom({
  key: 'selectedSection',
  default: null
});

export const reloadDepartmentsAtom = atom({
  key: 'reloadDepartments',
  default: false
});

export const reloadSectionsAtom = atom({
  key: 'reloadSections',
  default: false
});

export const referralEmailAtom = atom({
  key: 'referraEmail',
  default: false
});

export const customerInsightsTabAtom = atom({
  key: 'customerInsightsTab',
  default: 0
});

export const salesInsightsDateRangeAtom = atom({
  key: 'salesInsightsDateRange',
  default: null
});

export const selectedBusinessAccountPayoutsAtom = atom({
  key: 'selectedBusinessAccountPayouts',
  default: null
});

export const openHelpDrawerAtom = atom({
  key: 'openHelpDrawer',
  default: false
});

export const reloadBookingLocationAtom = atom({
  key: 'reloadBookingLocation',
  default: false
});

export const editBookingLocationAtom = atom({
  key: 'editBookingLocation',
  default: false
});

export const selectedBookingLocationAtom = atom({
  key: 'selectedBookingLocation',
  default: null
});

export const reloadBookingHostAtom = atom({
  key: 'reloadBookingHost',
  default: false
});

export const editBookingHostAtom = atom({
  key: 'editBookingHost',
  default: false
});

export const selectedBookingHostAtom = atom({
  key: 'selectedBookingHost',
  default: null
});

export const selectedEventForTimeSlotsAtom = atom({
  key: 'selectedEventForTimeSlots',
  default: null
});

export const selectedEventTimeSlotAtom = atom({
  key: 'selectedEventTimeSlot',
  default: null
});

export const reloadBookingEventAtom = atom({
  key: 'reloadBookingEvent',
  default: false
});

export const editBookingEventAtom = atom({
  key: 'editBookingEvent',
  default: false
});

export const selectedBookingEventAtom = atom({
  key: 'selectedBookingEvent',
  default: null
});

export const reloadSharedStoresAtom = atom({
  key: 'reloadSharedStores',
  default: false
});

export const showCreateSharedStoreAtom = atom({
  key: 'showCreateSharedStore',
  default: false
});

export const selectedInventoryItemAtom = atom({
  key: 'selectedInventoryItem',
  default: null
});

export const createDefaultPaymentOptionsAtom = atom({
  key: 'createDefaultPaymentOptions',
  default: false
});

export const editBusinessItemReloadAtom = atom({
  key: 'editBusinessReload',
  default: false
});
export const reloadCustomerCards = atom({
  key: 'reload-customer-cards',
  default: false,
});

export const reloadBookingServiceTypeAtom = atom({
  key: 'reloadBookingServiceType',
  default: false
});

export const editBookingServiceTypeAtom = atom({
  key: 'editBookingServiceType',
  default: false
});

export const selectedBookingServiceTypeAtom = atom({
  key: 'selectedBookingServiceType',
  default: null
});

export const reloadSubscriptionsAtom = atom({
  key: 'reloadSubscriptions',
  default: false
});

export const editSubscriptionAtom = atom({
  key: 'editSubscription',
  default: false
});

export const selectedSubscriptionAtom = atom({
  key: 'selectedSubscription',
  default: null
});

export const reloadSubscriptionBenefitsAtom = atom({
  key: 'reloadSubscriptionBenefits',
  default: false
});

export const selectedBenefitAtom = atom({
  key: 'selectedBenefit',
  default: null
});

export const editBenefitAtom = atom({
  key: 'editBenefit',
  default: false
});

export const reloadDocumentAcceptanceAtom = atom({
  key: 'reloadDocumentAcceptance',
  default: false
});

export const selectedEmployeeAtom = atom({
  key: 'selectedEmployee',
  default: null
});

export const openPerformanceDrawerAtom = atom({
  key: 'openPerformanceDrawer',
  default: false
});

export const reloadPerformanceDataAtom = atom({
  key: 'reloadPerformanceData',
  default: false
});

export const selectedTerminalDataAtom = atom({
  key: 'selectedTerminalData',
  default: null,
});

export const selectedItemForLogsAtom = atom({
  key: 'selectedItemForLogs',
  default: null
});

export const selectedBusinessItemForLogsAtom = atom({
  key: 'selectedBusinessItemForLogs',
  default: null
});

export const jsonPreviewAtom = atom({
  key: 'jsonPreview',
  default: null
});

export const selectedDepartmentOrSectionForLogsAtom = atom({
  key: 'selectedDepartmentOrSectionForLogs',
  default: null
});
